import React from "react"
import axios from "axios"
import queryString from "query-string"
import { navigate } from "gatsby"

import SEO from "../../components/seo"
import Layout from "../../components/layout"

import bookingform from "../../queries/bookingform"

import "../../styles/cart.css"

/**
 *
 *
 * @class Cart
 * @extends {React.Component}
 */
class PaymentConfirmation extends React.Component {
  GRAPHQL_API = process.env.GATSBY_PIH_URL

  // Initialising the state
  state = {
    paymentHeader: "Awaiting Booking Confirmation",
    paymentSubheader: "",
    paymentText: "Please do not close or refresh this page.",
  }

  /**
   * Creates an instance of Cart.
   * This method binds the component methods to this and sets up member variables from local storage
   * @param {*} props
   * @memberof Cart
   */
  constructor(props) {
    super(props)
    this.bindMethods()
    this.setPropsFromLocalStorage(props)
  }

  /**
   * This method pulls the data from local storage and initialises memeber variables
   * This method inialises jwt token, tour code, tour date and booking reference
   * @memberof Cart
   */
  setPropsFromLocalStorage(props) {
    let qs = queryString.parse(props.location.search)
    this.bookingRef =
      (typeof window !== "undefined" &&
        window.localStorage.getItem("bookingRef")) ||
      qs.bookingRef
    this.paymentSessionID =
      typeof window !== "undefined" &&
      window.localStorage.getItem("paymentSessionID")
    this.paymentConfirmed =
      typeof window !== "undefined" &&
      window.localStorage.getItem("paymentConfirmed")
  }

  /**
   * This method binds this to component functions
   * Unsure whether this is required
   * @memberof Cart
   */
  bindMethods() {
    this.checkPaymentConfirmation = this.checkPaymentConfirmation.bind(this)
    this.setSuccessState = this.setSuccessState.bind(this)
    this.setRetryState = this.setRetryState.bind(this)
    this.setFailureState = this.setFailureState.bind(this)
  }

  componentDidMount() {
    if (!this.paymentSessionID) {
      // No payment session
      navigate("/cart/")
      return
    }

    if (this.paymentConfirmed) {
      this.setSuccessState()
      return
    }

    this.checkPaymentConfirmation()
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  setSuccessState() {
    this.setState({
      paymentHeader: "Booking Confirmed",
      paymentSubheader: `Your booking reference number is ${this.bookingRef}`,
      paymentText: "Thank you for your booking.",
    })
  }

  setRetryState() {}

  setFailureState() {}

  checkPaymentConfirmation() {
    console.log("checkPaymentConfirmation check running")
    const query = bookingform.getPaymentConfirmation(this.bookingRef)
    axios.post(this.GRAPHQL_API, { query }, {}).then(response => {
      console.log("checkPaymentConfirmation", response)
      if (
        response.status !== 200 ||
        response.data.errors ||
        !response.data.data
      ) {
        // TODO Log error and retry slower?
      } else {
        /*
        APPROVED
        DECLINED
        DECLINED_RETRY_TRANSACTION
        INVALID_DATA
        UNKNOWN_RETRY_STATUS
        TIMEDOUT_OR_CANCELLED
        NOT_FOUND
        WAITING
        */
        let paymentStatus = response.data.data.getPaymentConfirmation.status

        // Payment was all good, finalise the booking in localStorage
        let successStatus = ["APPROVED"]
        // Warn the user and Link to /cart/checkout
        let retryStatus = ["DECLINED", "DECLINED_RETRY_TRANSACTION"]
        // ???
        let failureStatus = [
          "INVALID_DATA",
          "UNKNOWN_RETRY_STATUS",
          "TIMEDOUT_OR_CANCELLED",
          "NOT_FOUND",
        ]

        if (successStatus.includes(paymentStatus)) {
          clearTimeout(this.timer)
          typeof window !== "undefined" &&
            window.localStorage.setItem("paymentConfirmed", this.bookingRef)
          this.paymentConfirmed = this.bookingRef
          this.setSuccessState()
          return
        } else if (retryStatus.includes(paymentStatus)) {
          // TODO Log error and redirect back to /cart/checkout?
          clearTimeout(this.timer)
          this.setRetryState()
          return
        } else if (failureStatus.includes(paymentStatus)) {
          // TODO Log error and redirect back to /cart/checkout?
        } else if (paymentStatus === "WAITING") {
          // TODO Log error and redirect back to /cart/checkout?
          this.timer = setTimeout(this.checkPaymentConfirmation, 1000 * 2)
          console.log("checkPaymentConfirmation setTimeout(", this.timer, ")")
        } else {
          // ???
        }
      }
    })
  }

  render() {
    return (
      <>
        <SEO title="Payment Confirmation | New Zealand Bus Tours - awesomeNZ" />
        <Layout
          layoutType="typography TourPage"
          slideshow={
            <ul class="slideshow-slides">
              <li class="slideshow-slide first">
                <img
                  src={require("../../images/slideshow/hobbit-hole.jpg")}
                  alt=""
                  class="first last image"
                />
              </li>
            </ul>
          }
          hasSliderHandles={false}
          hasSliderBlurSmall={true}
          content={
            <div id="row" class="fourteen clearfix">
              <div class="title-div clearfix">
                <h1>{this.state.paymentHeader}</h1>
                <h3>{this.state.paymentSubheader}</h3>
              </div>
              <div id="Overview" class="clearfix">
                <div id="Content" class="clearfix">
                  <div id="ContentTab">
                    {/* Content from awesomeNZ tour page goes here */}
                    <p>{this.state.paymentText}</p>
                    {/* ... */}
                    <div class="clear"></div>
                  </div>
                </div>
              </div>
            </div>
          }
        />
      </>
    )
  }
}

export default PaymentConfirmation
